@import "../../bp.scss";

.outer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3% 0 0 0;
}

.top-container {
  display: flex;
  width: 900px;
  flex-direction: row;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 95%;
    padding: 25px;
  }
}

.project-title {
  font-weight: bolder;
  font-size: 2.5em;
}

.image-and-cta {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
    order: 0;
    margin-bottom: 10px;
  }
}

.imgd {
  max-width: 200px;
}

.cta {
  width: 100%;

  @media screen and (max-width: 750px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.description-container {
  margin: 0 20px;
  text-align: justify;

  @media screen and (max-width: 450px) {
    margin: 0;
  }
}

.tagstechs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px 0;
}

.technology {
  display: flex;
  flex-direction: column;
  border: 1px solid $lb;
  border-radius: 6px;
  margin: 5px;
  font-size: 0.8em;
  padding: 5px;
  min-width: 170px;
}

.individual-desc {
  font-size: 0.8em;
  text-align: left;
  padding: 1px 4px;
}

.individual-tag {
  color: white;
  font-size: 0.8em;
  font-weight: bolder;
  padding: 1px 4px;
}
