@import "../../bp.scss";

.email-dropdown-container {
    position: absolute;
    padding: 20px;
    top: 45px;
    left: 8px;
    width: 350px;
    height: 625px;
    background-color: $db;
    border: 2px solid $gr;
    border-radius: 8px;
    box-shadow: 4px 4px 8px black;
    z-index: 10;
}

.Toastify__toast-container--top-center {
    top: 50px;
}