body {
    background-color: #050F25;
    color: #fff;
    font-family: Calibri, sans-serif;
}

html, body, #root, .App {
    height: 100%;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   }