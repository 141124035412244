@import "../../bp.scss";

.nav-dropdown-container {
    position: absolute;
    z-index: 10;
    top: 45px;
    right: 10px;
    width: 350px;
    background-color: $db;
    border: 2px solid $gr;
    border-radius: 8px;
    color: black;
    display: flex;
    flex-direction: column;
    box-shadow: -4px 4px 8px black;
    padding: 20px;
}
