@import "../../bp.scss";

.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 6%;
    padding: 8% 10% 10% 10%;
    // height: 70%;
    
    @media screen and (max-width: 450px) {
      margin-top: 15%;
    }
   
}

.outer-box {
display: grid;
place-content: center;
height: 100vh;
padding: 20px;
}

.inner-box {
  border: 1px solid $lb;
  padding: 5px;
}