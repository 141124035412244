@import "../../bp.scss";

.sociallogo {
    font-weight: bolder;
    color: #fff;
    width: 25px;
    height: auto;
    fill: white;
    margin: 0px 5px;

    &:hover {
        fill: $gr;
        cursor: pointer;
    }
}