@import "../../bp.scss";

.nav-toggler {
    width: 25px;
    fill: white;
    margin-top: 6.5px;
    margin-left: 5px;
    line-height: 1.8;
    &:hover {
        fill: $gr;
        cursor: pointer;
    }
}