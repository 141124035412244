@import "../../bp.scss";

.headline-container {
    font-family: "Lucida Sans Unicode", sans-serif;
    font-size: 0.8em;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    
    @media screen and (max-width: 450px) {
        padding: 0px 5px 10px 15px;
    }
   
    @media screen and (min-width: $bp) {
        font-size: 1em;
        text-align: center;  
    }
}