.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 8px;
    width: 100%;
}

.totalContainer {
    display: flex;
    align-items: center;
    height: 100%;
}