@import "../../bp.scss";

.email-button {
    color: #fff;
    font-size: 0.8rem;
    border: 1px solid #8FAADC;
    padding: 8px 10px 5px 10px;
    border-radius: 7px;
    width: 80px;
    text-align: center;

    &:hover {
        background-color: $gr;
        cursor: pointer;
    }
}

.email-button-online {
        border: 2px solid $gr;
        color: #fff;
        font-size: 0.8rem;
        border: 2px solid rgb(112,173,71);
        padding: 8px 10px 5px 10px;
        border-radius: 7px;
        width: 80px;
        text-align: center;
    
        &:hover {
            cursor: pointer;
        }
    }