@import "../../bp.scss";

.request-cv-container {
    // display: flex;
    // flex-direction: column;
    // width: 100%;
    // justify-content: flex-end;
    // height: 100%;
    
    @media screen and (max-width: $bp) {
        margin-top: 20px;
      }

    form {
        display: inline;
        width: 100%;
    }

    // Phones
    // @media screen and (max-width: $bp) {
    //         padding: 30px 10px 10px 15px;
    //     }

    // // Laptops
    // @media screen and (min-width: $bp) {
    //     text-align: center;
    //     padding-top: 3%;
    // }

}

.second-chance {
    color: darkgrey;
    text-align: center;

    .emphasise {
        color: $gr;
    }
}

.Toastify__toast-container--top-center {
    top: 50px;
}
