@import "../../bp.scss";

// Remove default styling
button {
    border: 0;
}

.cbutton {
    background-color: #70AD47;
    color: white;
    padding: 10px;
    border-radius: 7px;
    font-weight: bold;
    text-align: center;
    height: 50px;
    width: 100%;
    margin: 3px 0;
    cursor: pointer;
    box-shadow: 4px 4px 8px black;
    outline: none;

    &:focus, &:hover {
        background-color: $dg;
        box-shadow: 6px 6px 10px black;
    }
}

.outlined {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid $lb;
}

.outlinedx {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid $lb;
    width: 199px;
}

.outlinesm {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid $lb;
    max-width: 130px;
    max-height: 30px;
}

.uber {
    border: 2px solid black;
    margin-top: 5px;
    box-shadow: 6px 6px 10px black;

    &:focus, &:hover {
        box-shadow: 10px 10px 15px black;
    }
}

.nofrills {
    border: none;
    background-color: transparent;
    margin-top: 5px;
    box-shadow: 6px 6px 10px black;

    &:focus, &:hover {
        box-shadow: 10px 10px 15px black;
    }
}

.codeview {
    border: 1px solid white;
    margin-top: 5px;
    width: 199px;

}