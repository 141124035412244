@import "../../bp.scss";

textarea {
    background-color: white;
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    margin: 3px 0;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    resize: none;
    box-shadow: 4px 4px 8px black;
    
    &:focus {
        border: 4px solid rgb(112,173,71);
    }
}
