@import "../../bp.scss";

.cb-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: center; 




}

.headline-pp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;

    @media screen and (max-width: 950px) {
     flex-direction: column;

    }
}
