@import "../../bp.scss";

.lpy-logo {
    font-weight: bolder;
    color: #fff;
    font-size: 1.8rem;
    align-items: center;
    text-decoration: none;
}

a {text-decoration: none;}

.dot {
    color: rgb(112,173,71);
    animation-name: flashdot;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
}

@keyframes flashdot {
    from {color: rgb(112,173,71)}
    to {color: white}
  };