@import "../../bp.scss";

.spark {
    width: 200px;
    margin-top: 50px;
    display: grid;
    place-content: center;
    background-color: $sd;
    height: 100px;
    // border: 1px solid white;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: rgba(100, 100, 207, 0.2) 0px 7px 29px 0px;
}