@import "../../bp.scss";

.project-block {
    background-color: black;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 50px;

}

.small-cta-projects {
    color: white;
    text-align: center;
    font-size: 1em;
    font-weight: bolder;
 }
